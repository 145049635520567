/* eslint-disable standard/no-callback-literal */
const testSelect = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
export default {
  entity_is_head_of_government: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_government_minister: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_court_judge: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_governor_of_bank: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_foreign_representative: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_armed_forces: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_chief_executive: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_relative_with_entity: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_is_ownership_of_entity: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  entity_confirm_checkbox: [
    { validator: testSelect, required: true, message: 'Please read and tick', trigger: 'change' }
  ]
}
